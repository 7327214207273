import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel-basic',
  templateUrl: './carousel-basic.component.html',
  styleUrls: ['./carousel-basic.component.scss']
})
export class CarouselBasicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
