<div class="question">
YASAL UYARI
</div>
<div class="description" translate>
  compliance.warrning
</div>
<div class="btn-container">
  <button class="btn btn-outline-danger" (click)="onCloseClick()">
    {{noButton}}
  </button>
</div>
<div class="footer">
  © 2020 Copyright:<a style="margin-left: 5px; color: darkred" href="http://www.aifasoft.com/" target="_blank">AIFASOFT</a>
</div>
