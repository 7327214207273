<!-- <footer class="text-white" >
    <div class="p-3" >
       <a href="https://www.aifanet.com/">Copyright © 2021 AifaNet All rights reserved</a> 
      <a class="text-black" href="http://www.aifasoft.com/" class="f_txt2" > <small>Powered  <a>by AIFASOFT</a></small></a>
    </div>
  </footer> -->

<footer class="footsie">
  <div class="leftFooter">
      <p>
         <span><a href="https://www.aifanet.com/" style="font-weight: bolder;">Copyright © 2021 AIFA-Soft All rights reserved</a> </span>
      </p>
  </div>
  <div class="rightFooter">
      <p>
       <span> <a class="text-right" href="http://www.aifasoft.com/" class="f_txt2"> <small>Powered  <a href="http://www.aifasoft.com/" style="font-weight: bolder;">by AIFASOFT</a></small></a></span>
      </p>
  </div>
</footer>