import { Component, OnInit } from '@angular/core';
import { CurrencyComponent } from 'src/app/currency/currency.component';
@Component({
  selector: 'app-footerinfo',
  templateUrl: './footerinfo.component.html',
  styleUrls: ['./footerinfo.component.scss']
})
export class FooterinfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
