import {  OnInit } from '@angular/core';
import AOS from 'aos';
import {Component} from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {


  constructor() {
  }


  ngOnInit(): void {
    AOS.init();
  }
}
