<!-- <br><br><br><br>
<div class="row justify-content-center m-0 text-center">
  <div class="col-12 p-0">
    <div class="bodyContainer" [style.height]="'400px'">
      <div
        *ngFor="let item of this.rssItems"#body>
        <div class="col bodyColumn text-left">
          <a  target="_blank" href="{{item.link}}">{{ item.title }}</a>
        </div>
      </div>
    </div>
  </div>
</div> -->



<table class="table">
    <thead>
      <tr *ngFor="let item of this.rssItems"#body>
        <th scope="col"><a  target="_blank" href="{{item.link}}">{{ item.title }}</a></th>
      </tr>
    </thead>
  </table>