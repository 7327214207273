<div class="container">
  <p style="color:white; " translate>complience.mainText </p>

  <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="docs//Musteri-kabul-politikasi.pdf"><span style="color:#9d8b54"
                                                                                                   translate>complience.customer</span></a></em>
  </p>
  <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="docs/Customer-Acceptance-Policy.pdf"><span
    style="color:#9d8b54" translate>complience.customer</span></a></em>
  </p>
  <p style="color:white; "><em><a href="docs/KYC-FORM-ENG.pdf"><span
    style="color:#9d8b54" translate>complience.kyc</span></a></em>
  </p>

  <p style="color:white; "><em><a href="docs/http://www.lbma.org.uk/assets/market/gdl/RGG%20v6.0%20201508014.pdf"
                                  target="_blank"><span
    style="color:#9d8b54" translate>complience.lbma</span></a></em></p>

  <p style="color:white; "><em><a href="docs/http://www.oecd.org/daf/inv/mne/GoldSupplement.pdf" target="_blank"><span
    style="color:#9d8b54" translate>complience.oecd</span></a></em></p>

  <p style="color:white; "><em><a
    href="docs/http://www.gold.org/sites/default/files/documents/Conflict_Free_Gold_Standard_English.pdf"
    target="_blank"><span style="color:#9d8b54" translate>complience.world</span></a></em></p>

  <p style="color:white; "><em><a href="docs/http://www.masak.gov.tr/" target="_blank"><span
    style="color:#9d8b54" translate>complience.masak</span></a><br>
    <a href="docs/http://www.borsaistanbul.com/" target="_blank"><span
      style="color:#9d8b54" translate>complience.borsa</span></a><br>
    <a href="docs/Bagimsiz-denetim-raporu.pdf"><span style="color:#9d8b54" translate>complience.bagimsiz</span></a><br>
  </em>
  </p>

  <p style="color:white; "><em><a href="docs/Cerceve_Sozlesme.pdf"><span
    style="color:#9d8b54" translate>complience.cerceve</span></a></em>
  </p>

  <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="docs/Musteri_Kabul_Beyani.pdf"><span
    style="color:#9d8b54" translate>complience.kabul</span></a></em>
  </p>
  <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="docs/Declaration.pdf"><span
    style="color:#9d8b54" translate>complience.kabul</span></a></em>
  </p>

  <p style="color:white; "><em><a href="docs/Musteri_Tani_Formu.pdf"><span
    style="color:#9d8b54" translate>complience.tani</span></a></em></p>

  <p style="color:white; "><em><a href="docs/Musteri_Yetki_Belgesi.pdf"><span
    style="color:#9d8b54" translate>complience.yetki</span></a></em></p>

  <p style="color:white; "><em><a href="docs/Cerez-Aydinlatma-Metni.pdf"><span
    style="color:#9d8b54" translate>complience.cerez</span></a></em></p>

  <p style="color:white; "><em><a href="docs/Veri-Sahibi-Basvuru-Formu.pdf"><span
    style="color:#9d8b54" translate>complience.veri</span></a></em></p>

  <p style="color:white; "><em><a href="docs/KVKK_Aydınlatma_Metni.pdf"><span
    style="color:#9d8b54" translate>complience.kvkk</span></a></em></p>

    <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="docs/Kontrol_Sistemi_Ve_Tedarik.pdf"><span
      style="color:#9d8b54" translate>complience.kontrol_sistemi</span></a></em></p>

      <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="docs/Control_System_And_Supply.pdf"><span
        style="color:#9d8b54" translate>complience.kontrol_sistemi</span></a></em></p>

<!-- burdan sonra -->

<p *ngIf="lang === 'tr'" style="color:white; "><em><a href="docs/hasborsauyum.pdf"><span
  style="color:#9d8b54" translate>complience.borsa</span></a></em></p>

  <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="docs/hasborsauyum.pdf"><span
    style="color:#9d8b54" translate>complience.borsa</span></a></em></p>

    <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="docs/hasyonetim.pdf"><span
      style="color:#9d8b54" translate>complience.yonetim</span></a></em></p>
    
      <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="docs/hasyonetim.pdf"><span
        style="color:#9d8b54" translate>complience.yonetim</span></a></em></p>

        <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="docs/hasguvence.pdf"><span
          style="color:#9d8b54" translate>complience.guvence</span></a></em></p>
        
          <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="docs/hasguvence.pdf"><span
            style="color:#9d8b54" translate>complience.guvence</span></a></em></p>

            <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="docs/hasyonetim2023.pdf"><span
              style="color:#9d8b54" translate>complience.yonetim2023</span></a></em></p>
              
              <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="docs/hasyonetim2023.pdf"><span
                style="color:#9d8b54" translate>complience.yonetim2023</span></a></em></p>

                <p *ngIf="lang === 'tr'" style="color:white; "><em><a href="docs/hasguvenuyum2023.pdf"><span
                  style="color:#9d8b54" translate>complience.guvence2023</span></a></em></p>

                  <p *ngIf="lang === 'eng'" style="color:white; "><em><a href="docs/hasguvenuyum2023.pdf"><span
                    style="color:#9d8b54" translate>complience.guvence2023</span></a></em></p>


</div>
