import { Component, OnInit } from '@angular/core';
import {TranslateServices} from '../_services/translate.service';

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent implements OnInit {
 
  lang = 'tr';
  selectedValue = 'tr';

  constructor(  private translateServices: TranslateServices) {}

  ngOnInit(): void {  } 

  changeLang() {
    if (this.lang === 'eng') {
      this.lang = 'tr';
    } else {
      this.lang = 'eng';
    }
    this.translateServices.changeLanguage(this.lang);
  }
}
