<div class="slider">
  <div class="slide-track">
    <div class="slide" *ngFor="let data of footerList; trackBy : trackByPrice">
      <div class="text-parity">
        {{data.Code}}
      </div>
      <div class="text-price">
        {{data.Ask | number:'1.2-4'}}
      </div>
    </div>
  </div>
</div>